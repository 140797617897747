import React from "react"
import styled from "styled-components"

import media from "../components/media"
import thank from "../images/thanks.jpg"
import Layout from "../components/layout"
import GlobalStyle from "../components/globalStyle"
import SEO from "../components/seo"

const ImageDiv = styled.div`
  position: relative;
  height: 100vh;
  background: center no-repeat url(${thank});
`

const H1 = styled.h1`
  background: rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.8);
  color: white;
  font-family: "SpaceGrotesk";
  font-size: 8rem;
  position: absolute;
  top: 30px;
  left: 60px;
  ${media.phone`font-size: 5rem;`}
`
const R = styled.span`
  color: #ff4136;
`

const Marketing = styled.h2`
  background: rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.8);
  color: white;
  font-family: "SpaceGrotesk";
  font-size: 4rem;
  ${media.phone`font-size: 3rem;`}
  ${media.phone`margin-right: 2rem;`}
  position: absolute;
  top: 250px;
  left: 60px;
`

const Thanks = () => (
  <Layout>
    <GlobalStyle />
    <SEO title="Thank you" />
    <ImageDiv>
      <H1>
        Thank<R>s</R>
      </H1>
      <Marketing>We will be in contact</Marketing>
    </ImageDiv>
  </Layout>
)

export default Thanks
